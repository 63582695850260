.home {
  display: grid;
}

.home__book-ad {
  display: grid;
  grid-column-gap: 1rem;
}

.home__book-cover {
  opacity: 1;
  width: 100%;
}

@media (max-width: 767px) {
  .home {
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  .home {
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
  }

  .home__book-ad {
    grid-template-columns: 33% 1fr;
  }

  .home__header {
    background-image: url('/header.jpg');
    background-position: center;
    background-size: cover;
    height: 420px;
    grid-column: 1 / span 4;
    opacity: 0.85;
  }
}

@media (max-width: 1100px) {
  .home__book-ad {
    grid-template-columns: 1fr;
  }
}

.home__main {
  grid-column: 1 / span 3;
}

.home__about {
  margin-top: 2rem;
}
