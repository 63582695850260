body {
  padding: 1rem;
}

@media (min-width: 768px) {
  body {
    background-image: url('/autumn-landscape.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
}
